import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { NAMESPACE } from "../../global/i18n";
import { Views } from "../../global/model";
import { smoothScrollTop } from "../../components/shared/utils";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { OverviewContent, OverviewImage, Section } from "../../components/layout/Section";
import { DraggableCarousel, RollingCarousel } from "../../components/layout/Carousel";

import HomeStudioMusicPicture from "../../assets/content/home-studio-music-studio.jpg";
import logoUniversiteLaval from "../../assets/logo/logo-universite-laval.svg";
import logoVilleLevis from "../../assets/logo/logo-ville-levis.svg";
import logoBeenox from "../../assets/logo/logo-Beenox.svg";
import logoManeige from "../../assets/logo/logo-maneige.svg";
import logoVireo from "../../assets/logo/logo-vireo.png";
import logoToiture from "../../assets/logo/logo-toiture-quebec.png";
import logoLesPeres from "../../assets/logo/logo-les-peres-nature.svg";
import logoArtofix from "../../assets/logo/logo-artofix.png";


const Video = styled(Section)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  height: "100%",
}));

const Clients = styled(Section)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: theme.spacing(8)
}));

const Services = styled(Section)(({ theme }) => ({
  display: "flex",
  flexDirection: "row-reverse",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: theme.spacing(8),

  [theme.breakpoints.down("md")]: {
    width: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  }
}));

const Comments = styled(Section)(({ theme }) => ({
  marginTop: theme.spacing(8),
}));

const Home = () => {
  const { t } = useTranslation([NAMESPACE.NAVIGATION, NAMESPACE.SHARED, Views.HOME, Views.SERVICES]);
  const navigate = useNavigate();
  const theme = useTheme();

  const goTo = (path: string) => {
    navigate(path);
    smoothScrollTop();
  };

  const logos = [
    {
      src: logoUniversiteLaval,
      alt: "Université Laval"
    },
    {
      src: logoVilleLevis,
      alt: "Ville de Lévis"
    },
    {
      src: logoBeenox,
      alt: "Beenox"
    },
    {
      src: logoManeige,
      alt: "Manège Militaire"
    },
    {
      src: logoVireo,
      alt: "Vireo"
    },
    {
      src: logoLesPeres,
      alt: "Les Pères Nature"
    },
    {
      src: logoToiture,
      alt: "Toiture Québec"
    },
    {
      src: logoArtofix,
      alt: "Artofix"
    }
  ]

  const comments = [
    {
      name: t(`${Views.HOME}:comments.clients.first.name`),
      title: t(`${Views.HOME}:comments.clients.first.title`),
      picture: "https://api.dicebear.com/7.x/miniavs/svg?seed=Felix",
      comment: t(`${Views.HOME}:comments.clients.first.comment`)
    },
    {
      name: t(`${Views.HOME}:comments.clients.second.name`),
      title: t(`${Views.HOME}:comments.clients.second.title`),
      picture: "https://api.dicebear.com/7.x/miniavs/svg?seed=50",
      comment: t(`${Views.HOME}:comments.clients.second.comment`)
    },
    {
      name: t(`${Views.HOME}:comments.clients.third.name`),
      title: t(`${Views.HOME}:comments.clients.third.title`),
      picture: "https://api.dicebear.com/7.x/miniavs/svg?seed=1",
      comment: t(`${Views.HOME}:comments.clients.third.comment`)
    },
    {
      name: t(`${Views.HOME}:comments.clients.fourth.name`),
      title: t(`${Views.HOME}:comments.clients.fourth.title`),
      picture: "https://api.dicebear.com/7.x/miniavs/svg?seed=200",
      comment: t(`${Views.HOME}:comments.clients.fourth.comment`)
    }
  ];

  return (
    <>
      <Video bgVideo={"/Videos/lufs-home-bg-video.mp4"} header>
        <Typography variant={"h2"} fontWeight={"bolder"} textAlign={"center"} color={"primary.light"}>
          {t(`${Views.HOME}:header.title`)}
        </Typography>
        <Typography variant={"h5"} fontWeight={{xs: 400, md: "bold"}} textAlign={"center"} color={"secondary.light"}
          width={{xs: "100%", md: "65%"}} paddingBottom={2}>
          {t(`${Views.HOME}:header.subtitle`)}
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          sx={{ fontWeight: "bold" }}
          onClick={() => goTo("/contact")}
        >
          RÉSERVER
        </Button>
      </Video>
      <Clients>
        <Typography variant={"h4"} fontWeight={"bold"} marginBottom={2}>
          {t(`${Views.HOME}:clients.title`)}
        </Typography>
        <RollingCarousel
          items={logos}
          height={"56px"}
          color={theme.palette.secondary.main}
        />
      </Clients>
      <Services enableObserver>
        <OverviewImage
          position={"left"}
          transition={"fade"}
          src={HomeStudioMusicPicture}
        />
        <OverviewContent
          position={"left"}
          transition={"slide"}
          title={t(`${Views.SERVICES}:header.title`)}
          subtitle={t(`${Views.SERVICES}:header.subtitle`)}
          buttonLabel={t(`${NAMESPACE.SHARED}:learnMore`)}
          buttonPath={t(`${NAMESPACE.NAVIGATION}:${Views.SERVICES}.path`)}
        />
      </Services>
      <Comments>
        <Typography variant={"h4"} fontWeight={"bold"} marginBottom={4} textAlign={"center"}>
          {t(`${Views.HOME}:comments.title`)}
        </Typography>
        <DraggableCarousel items={comments} color={theme.palette.secondary.main} autoPlay timeout={7000}/>
      </Comments>
    </>
  );
};

export default Home;

export const about = {
  header: {
    title: "Mettre au monde tes plus beaux projets créatifs.",
    description: `Le Studio d'enregistrement Balance est une station de travail créative axée sur la proximité entre 
      l’artiste et l’ingénieur. Le studio se spécialise dans la production musicale intégrale de tout genre ainsi que 
      dans la postproduction audiovisuelle.`
  },
  history: {
    title: "Officiellement fondé en 2022, mais dans le milieu depuis cinq ans.",
    description: "Le Studio se consacre à la création de sonorités les plus modernes en étant constamment à l’affût des dernières tendances de l’industrie; là est l’un de ses plus grands atouts. La régie du studio est équipée de matériel analogique et audio numérique des marques les plus évoluées technologiquement telles que Universal Audio, Adam Audio, Avantone et plusieurs autres."
  },
  location: {
    title: "Situé au cœur de la ville de Québec",
    description: "Des dizaines de musiciens.nes et de réalisateurs.trices locaux profitent depuis maintenant cinq ans des services du fondateur du Studio Balance."
  }
}
export type ItemPosition = "left" | "right" | "center";

export type ItemTransition = "slide" | "fade" | "none";

type FlexPlacementType = "justifyContent" | "alignItems" | "flexDirection";

export const emailRegex: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const getFlexProperty = (position: ItemPosition, type: FlexPlacementType): string => {
  if (position === "left") {
    if (type === "justifyContent" || type === "alignItems") {
      return "flex-start";
    } else if (type === "flexDirection") {
      return "row";
    }
  } else if (position === "right") {
    if (type === "justifyContent" || type === "alignItems") {
      return "flex-end";
    } else if (type === "flexDirection") {
      return "row-reverse";
    }
  } else {
    return "center";
  }
  return "";
};

export const smoothScrollTop = (): void => {
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
};

export const isEmpty = (text: string): boolean => {
  return text === "";
};

import { CircularProgress, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import { FormEvent, useState } from "react";
import { styled } from "@mui/material/styles";
import { useLoading, useToast } from "../../hooks";
import { sendMailContactForm } from "../../../services";
import { ContactFormData } from "../../../global/model";
import { emailRegex, isEmpty } from "../../shared/utils";


const Form = styled("form")(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    width: "100%"
  },
  [theme.breakpoints.up("sm")]: {
    width: "90%"
  },
  [theme.breakpoints.up("md")]: {
    width: "80%"
  }
}));

const FormInput = styled(TextField)(({ theme }) => ({
  // Other way to do
  // [`&.${ formHelperTextClasses.root }`]: {
  //   borderColor: "red"
  // },
  "& .MuiFormHelperText-root": {
    color: theme.palette.text.primary
  },
  "& .MuiFormLabel-root": {
    color: theme.palette.text.primary
  },

  "& .MuiFormValue-root": {
    color: theme.palette.text.primary
  },

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.primary.main
  },

  "& input + fieldset, & textarea + fieldset": {
    borderColor: theme.palette.text.primary,
    borderWidth: 1
  },
  "& input:required + fieldset, & textarea:required + fieldset": {
    borderColor: theme.palette.text.primary,
    borderWidth: 1
  }
}));

export const ContactForm = () => {
  const { openToast } = useToast();
  const [isLoading, loadingCallback] = useLoading();
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const initialFormState: ContactFormData = {
    name: "",
    email: "",
    subject: "",
    message: ""
  };
  const [data, setData] = useState<ContactFormData>(initialFormState);

  const setSpecificData = (key: string, value: string): void => {
    setData((prevState) => ({
      ...prevState,
      [key]: value
    }));
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    const form: HTMLFormElement = document.getElementById("contactForm") as HTMLFormElement;
    if (form.checkValidity()) {
      await loadingCallback(
        sendMailContactForm(data)
          .then(() => {
            openToast("Courriel envoyé", "success");
            setFormSubmitted(false);
            setData(initialFormState);
          })
          .catch(() => {
            openToast("Échec d'envoi du courriel, veuilliez réessayer plus tard", "error");
          })
      );
    } else {
      openToast("Certaines informations sont invalides", "error");
    }
  };

  return (
    <Form onSubmit={(event) => handleSubmit(event)} id="contactForm">
      <FormInput
        label="Nom"
        variant="outlined"
        margin="normal"
        required={isEmpty(data.name)}
        fullWidth
        value={data.name}
        onChange={(e) => setSpecificData("name", e.target.value)}
        error={formSubmitted && isEmpty(data.name)}
        helperText={isEmpty(data.name) ? "Veuillez entrer votre nom complet." : ""}
      />
      <FormInput
        type="email"
        label="Adresse courriel"
        variant="outlined"
        margin="normal"
        required={isEmpty(data.email)}
        fullWidth
        value={data.email}
        onChange={(e) => setSpecificData("email", e.target.value)}
        inputProps={{ pattern: JSON.stringify({ flags: emailRegex.flags, source: emailRegex.source }) }}
        error={formSubmitted && (isEmpty(data.email) || !emailRegex.test(data.email))}
        helperText={isEmpty(data.email) ? "Veuillez entrer votre adresse courriel." : ""}
      />
      <FormInput
        label="Sujet"
        variant="outlined"
        margin="normal"
        required={isEmpty(data.subject)}
        fullWidth
        value={data.subject}
        onChange={(e) => setSpecificData("subject", e.target.value)}
        error={formSubmitted && isEmpty(data.subject)}
        helperText={isEmpty(data.subject) ? "Veuillez entrer le sujet de votre courriel" : ""}
      />
      <FormInput
        multiline
        label="Message"
        variant="outlined"
        margin="normal"
        required={isEmpty(data.message)}
        rows={5}
        fullWidth
        value={data.message}
        onChange={(e) => setSpecificData("message", e.target.value)}
        error={formSubmitted && isEmpty(data.message)}
        helperText={isEmpty(data.message) ? "Veuillez décrire votre demande" : ""}
      />
      <Button onClick={() => setFormSubmitted(true)} type="submit" variant="contained" color="primary" fullWidth>
        {isLoading ? <CircularProgress size={24} color="inherit" /> : "Envoyer"}
      </Button>
    </Form>
  );
};

import { useState } from "react";


export const useLoading = (): [isLoading: boolean, loadingCallback: (callback: Promise<any>) => Promise<void>] => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loadingCallback = async (callback: Promise<any>) => {
    setIsLoading(true);
    await callback;
    setIsLoading(false);
  };

  return [isLoading, loadingCallback];
};

import React from "react";
import { Container, Theme } from "@mui/material";
import { SxProps } from '@mui/system';


type Props = {
  sx?: SxProps<Theme>;
  className?: string;
  children: React.ReactElement | React.ReactElement[];
};

export const Viewport = ({ sx, className, children }: Props) => {
  return (
    <Container sx={sx} className={className} maxWidth="lg">
      {children}
    </Container>
  );
};

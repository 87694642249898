import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import {
  fr_about,
  fr_audioBook,
  fr_contact,
  fr_footer,
  fr_home,
  fr_musicProduction,
  fr_navigation,
  fr_podcast,
  fr_postProduction,
  fr_realizations,
  fr_services,
  fr_shared
} from "./translation/fr";
import { NAMESPACE } from "./namespace";
import { LANGUAGE } from "./language";
import { Views } from "../model";


export const resources = {
  fr: {
    [NAMESPACE.SHARED]: fr_shared,
    [NAMESPACE.NAVIGATION]: fr_navigation,
    [NAMESPACE.FOOTER]: fr_footer,
    [Views.HOME]: fr_home,
    [Views.ABOUT]: fr_about,
    [Views.SERVICES]: fr_services,
    [Views.MUSIC_PRODUCTION]: fr_musicProduction,
    [Views.POST_PRODUCTION]: fr_postProduction,
    [Views.PODCAST]: fr_podcast,
    [Views.AUDIO_BOOK]: fr_audioBook,
    [Views.REALIZATIONS]: fr_realizations,
    [Views.CONTACT]: fr_contact
  }
};

i18n.use(initReactI18next).init({
  lng: LANGUAGE.FRENCH,
  ns: Object.keys(NAMESPACE).concat(Object.keys(Views)),
  resources: resources
});

export default i18n;

import { Toast, ToastContext } from "./ToastContext";
import { useState } from "react";
import { Alert, Slide, Snackbar } from "@mui/material";


type Props = {
  children: JSX.Element;
};

export const ToastProvider = ({ children }: Props) => {
  const [toast, setToast] = useState<Toast>({
    open: false,
    message: "",
    severity: "success"
  });

  const openToast = (message: string, severity: any) => {
    setToast({ open: true, message, severity });
  };

  const closeToast = () => {
    setToast({ ...toast, open: false });
  };

  return (
    <ToastContext.Provider value={{ openToast, closeToast, toast: toast }}>
      {children}
      {toast.open && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          TransitionComponent={(props) => <Slide {...props} direction="left" />}
          open={toast.open}
          autoHideDuration={3000}
          onClose={closeToast}
        >
          <Alert severity={toast.severity} onClose={closeToast}>
            {toast.message}
          </Alert>
        </Snackbar>
      )}
    </ToastContext.Provider>
  );
};

import { ItemPosition, ItemTransition } from "./utils";
import { Fade, Slide } from "@mui/material";
import React from "react";


type Props = {
  isVisible?: boolean;
  transition: ItemTransition;
  position: ItemPosition;
  timeout?: number;
  children: React.ReactElement;
};

export const Transition = ({ isVisible, transition, position, timeout = 1000, children }: Props) => {
  switch (transition) {
    case "slide":
      return (
        <Slide in={isVisible} direction={position === "right" ? "left" : "right"} timeout={timeout} mountOnEnter unmountOnExit>
          {children}
        </Slide>
      );
    case "fade":
      return (
        <Fade in={isVisible} timeout={timeout}>
          {children}
        </Fade>
      );
    default:
      return children;
  }
};

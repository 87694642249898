import { ObserverProps } from "../Types";
import { styled } from "@mui/material/styles";
import { ItemPosition, ItemTransition } from "../../../shared/utils";
import React from "react";
import { Transition } from "../../../shared/Transition";


const Image = styled("img")(() => ({
  opacity: 0.8,
  width: "75%",
  borderRadius: "10px",
  // border: "10px solid transparent",
  // borderImage: `radial-gradient(circle, rgba(251,235,203,0) 0%, rgba(251,235,203,1) 100%)`,
  // borderImageSlice: 1,
  boxShadow: "0 0 20px 10px rgba(0, 0, 0, 0.75)"
}));

const Display = styled("div")<{ position: ItemPosition }>(({ theme, position }) => ({
  width: "50%",
  display: "flex",
  justifyContent: position === "left" ? "flex-start" : "flex-end",


  [theme.breakpoints.down("md")]: {
    width: "100%",
    justifyContent: "center"
  }
}));

type Props = ObserverProps & {
  src: string;
  position: ItemPosition;
  transition?: ItemTransition;
};

export const OverviewImage = ({ isVisible, src, position, transition = "none" }: Props) => {
  return (
    <Transition position={position} transition={transition} isVisible={isVisible}>
      <Display position={position}>
        <Image src={src} />
      </Display>
    </Transition>
  );
};

import React from "react";
// import LUFSFooterLogo from "../../../../assets/logo/lufs-main-gray-logo.svg";
import StudioBalanceLogo from "../../../../assets/logo/studio-balance-logo-v1.png";
import { styled, useTheme } from "@mui/material/styles";
import { Button, ButtonGroup, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../../../global/i18n";


const Container = styled("div")(({ theme }) => ({
  width: "50%",
  [theme.breakpoints.down("md")]: {
    width: "100%"
  }
}));

const Display = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center"
  }
}));

const Logo = styled("img")(({ theme }) => ({
  height: "56px"
}));

const Info = styled("div")(({ theme }) => ({
  marginTop: 30,
  marginBottom: 30
}));

const EmailInput = styled(TextField)(({ theme }) => ({
  width: "100%",
  backgroundColor: "white",
  borderTopLeftRadius: theme.shape.borderRadius,
  borderBottomLeftRadius: theme.shape.borderRadius,
  // '& label': {
  //     color: theme.palette.ternary.main
  // },
  // '& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused': {
  //     color: 'green',
  // },

  "& .MuiFilledInput-root": {
    color: theme.palette.text.secondary,
    "&:hover:before": {
      borderColor: "none",
      borderBottomLeftRadius: theme.shape.borderRadius
    },
    "&:after": {
      borderBottomLeftRadius: theme.shape.borderRadius
    },
    "&:before": {
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderColor: theme.palette.grey["100"]
    }
  }
}));

const EmailButton = styled(Button)({
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0
});

export const Newsletter = () => {
  const { t } = useTranslation([NAMESPACE.FOOTER]);
  const theme = useTheme();

  return (
    <Container>
      <Display>
        <Logo src={StudioBalanceLogo} alt="LUFS logo" />
      </Display>
      <Info>
        <Typography fontSize={{ xs: 20, md: 25 }} fontWeight="bold">
          {t(`${NAMESPACE.FOOTER}:newsletter.title`)}
        </Typography>
        <Typography>{t(`${NAMESPACE.FOOTER}:newsletter.description`)}</Typography>
      </Info>
      <ButtonGroup variant="contained" aria-label="outlined primary button group" style={{ width: "100%" }}>
        <EmailInput label={t(`${NAMESPACE.FOOTER}:newsletter.label`)} variant="filled" color="secondary" size={"small"} />
        <EmailButton variant="contained" color="secondary">
          {t(`${NAMESPACE.FOOTER}:newsletter.button`)}
        </EmailButton>
      </ButtonGroup>
    </Container>
  );
};

import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../../global/i18n";
import { Views } from "../../../global/model";
import { Typography, useTheme } from "@mui/material";
import { Section } from "../../../components/layout/Section";
import React from "react";
import { styled } from "@mui/material/styles";
import { ItemPosition } from "../../../components/shared/utils";

import SectionRecordingPicture from "../../../assets/content/music-studio-view-recording.png";
import RecordingServicesPicture from "../../../assets/content/music-studio-overview-recording.jpg";


const Header = styled(Section)(({theme}) => ({
  paddingTop: theme.spacing(20),

  [theme.breakpoints.down("md")]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 0
  }
}));

const Music = styled(Section)<{ position: ItemPosition }>(({theme, position}) => ({
  display: "flex",
  flexDirection: position === "left" ? "row-reverse" : "row",
  justifyContent: "space-between",
  alignItems: "center",

  [theme.breakpoints.down("md")]: {
    width: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },

  [theme.breakpoints.down("sm")]: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  }
}));

const Image = styled("img")(({theme}) => ({
  height: "50vh",
  marginRight: theme.spacing(4),

  [theme.breakpoints.down("md")]: {
    marginRight: 0
  }
}));


export const MusicProduction = () => {
  const {t} = useTranslation([NAMESPACE.SHARED, Views.MUSIC_PRODUCTION]);
  const theme = useTheme();

  return (
    <>
      <Header bgImage={ RecordingServicesPicture } header>
        <Typography variant={"h2"} color={theme.palette.text.secondary} fontWeight={"bold"}
          textAlign={{xs: "center", md: "left"}}>
          { t(`${ Views.MUSIC_PRODUCTION }:title`) }
        </Typography>
      </Header>
      <Music minHeight={"100vh"} bgColor={theme.palette.nav.light} position={"right"} enableObserver>
        <Image src={ SectionRecordingPicture }/>
        <Typography variant={ "body1" }>
          { t(`${ Views.MUSIC_PRODUCTION }:subtitle`) }
        </Typography>
      </Music>
    </>
  );
};

import { Views } from "../../../../model";


export const services = {
  header : {
    title: "Services",
    subtitle: "Enregistrement, arrangement, mixage et mastering de chansons dans un studio décontracté."
  },
  [Views.MUSIC_PRODUCTION]: {
    title: "Production Musicale",
    subtitle: `L’enregistrement est le processus à la base de la chaîne de production musicale. Il s’agit de la 
      captation de tous les instruments et voix composants une chanson. Au courant d’une session d’enregistrement, 
      le montage de la structure d’un morceau se concrétise, puis il y a également ajout de transitions créatives 
      entre les différentes parties.`
  },
  [Views.POST_PRODUCTION]: {
    title: "Post-production",
    subtitle: `L’editing est la ‘’job de bras’’ de la production musicale. Il consiste à améliorer manuellement le 
      différentes pistes audio d’une chanson sur les aspects de synchronisation au tempo, de l’atteinte des bonnes notes 
      et de la normalisation du volume. L’editing est également très pratique pour la préparation et l’organisation d’une
      session de mixage.`
  },
  [Views.PODCAST]: {
    title: "Podcast",
    subtitle: `Le mixage est le traitement individuel de chaque piste instrumentale et des différents groupes 
      d’instruments. L’objectif derrière cette étape est la création de la balance musicale entre les différents 
      instruments via l’égalisation des fréquences et la compression du volume.`
  },
  [Views.AUDIO_BOOK]: {
    title: "Livre Audio",
    subtitle: `Le mastering d’une chanson est le traitement final du fichier unique. Il consiste à maximiser le volume de 
      sortie à un niveau compétitif pour toutes les plateformes de streaming et à ajuster la plage dynamique finale ainsi 
      que l’équilibre fréquentiel de la chanson.  Il y a également ajout de couleurs analogiques.`
  },
}
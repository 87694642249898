import * as React from "react";
import { bindHover, PopupState } from "material-ui-popup-state/hooks";
import { Button as MuiButton } from "@mui/material";
import { NavbarLink } from "../../../shared/styled";
import { styled } from "@mui/material/styles";
import { smoothScrollTop } from "../../../shared/utils";


const PaddedDiv = styled("div")`
  padding-top: 10px;
  padding-bottom: 10px;
`;

type Props = {
  path: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  popupState?: PopupState;
  endIcon?: React.ReactNode;
  children: React.ReactNode;
};

export const Button = ({ path, onClick, popupState, endIcon, children }: Props) => {
  const endIconProps = () => {
    if (endIcon !== undefined) {
      return {
        endIcon: endIcon
      };
    }
  };

  const popupStateProps = () => {
    if (popupState !== undefined) {
      return {
        ...bindHover(popupState)
      };
    }
  };

  return (
    <PaddedDiv {...popupStateProps()}>
      <MuiButton
        disableElevation
        onClick={onClick}
        color="inherit"
        sx={{
          color: "i18n.primary",
          "&.MuiButtonBase-root:hover": {
            bgcolor: "nav.light"
          }
        }}
        {...endIconProps()}
      >
        <NavbarLink to={path} onClick={() => smoothScrollTop()}>
          {children}
        </NavbarLink>
      </MuiButton>
    </PaddedDiv>
  );
};

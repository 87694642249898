import { Views } from "../../../../model";


export const navigation = {
  [Views.HOME]: {
    name: "LUFS",
    path: "/"
  },
  [Views.ABOUT]: {
    name: "À propos",
    path: "/a-propos"
  },
  [Views.SERVICES]: {
    name: "Services",
    path: "/services",
    subViews: {
      [Views.MUSIC_PRODUCTION]: {
        name: "Production musicale",
        path: "/services/production-musicale"
      },
      [Views.POST_PRODUCTION]: {
        name: "Post-production",
        path: "/services/post-production"
      },
      [Views.PODCAST]: {
        name: "Podcast",
        path: "/services/podcast"
      },
      [Views.AUDIO_BOOK]: {
        name: "Livre audio",
        path: "/services/livre-audio"
      }
    }
  },
  [Views.REALIZATIONS]: {
    name: "Réalisations",
    path: "/realisations"
  },
  [Views.CONTACT]: {
    name: "Contact",
    path: "/contact"
  }
};

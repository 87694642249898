import React from "react";
// import LUFSNavbarLogo from "../../../../assets/logo/lufs-main-gray-logo.svg";
import StudioBalanceLogo from "../../../../assets/logo/studio-balance-logo-v1.png";
import { NavLink } from "react-router-dom";
import { Button } from "@mui/material";


export const Logo = () => {
  return (
    <NavLink style={{ display: "flex", alignItems: "center" }} to="/">
      <Button
        color="inherit"
        sx={{
          color: "i18n.primary",
          "&.MuiButtonBase-root:hover": {
            bgcolor: "nav.light"
          }
        }}
      >
        <img src={StudioBalanceLogo} style={{ height: 50 }} alt="LUFS logo" />
      </Button>
    </NavLink>
  );
};

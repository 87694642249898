import React, { useState } from "react";
import { AppBar, IconButton, Slide, Toolbar, useMediaQuery, useScrollTrigger, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Viewport } from "../../shared/Viewport";
import { Logo } from "./Items/Logo";
import Slider from "./Items/Slider";
import MenuIcon from "@mui/icons-material/Menu";
import { navbarMetadata } from "./metadata";
import { Desktop } from "./Items/Desktop";


const FittingToolBar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;

  @media (min-width: 600px) {
    padding: 0;
  }
`;

interface Props {
  children: React.ReactElement;
}

const HideOnScroll = (props: Props) => {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

export const Navbar = () => {
  const theme = useTheme();
  const responsive = useMediaQuery(theme.breakpoints.down("md"));

  const [open, setOpen] = useState<boolean>(false);
  const toggleDrawer = (open: boolean, event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }
    setOpen(open);
  };

  return (
    <>
      {responsive && <Slider state={open} toggle={toggleDrawer} data={navbarMetadata} />}
      <HideOnScroll>
        <AppBar sx={{ bgcolor: "nav.main" }}>
          <Viewport>
            <FittingToolBar>
              <Logo />
              {responsive ? (
                <IconButton onClick={(e) => toggleDrawer(true, e)}>
                  <MenuIcon color={"primary"} fontSize={"large"} />
                </IconButton>
              ) : (
                <Desktop data={navbarMetadata} />
              )}
            </FittingToolBar>
          </Viewport>
        </AppBar>
      </HideOnScroll>
    </>
  );
};

import React, { useEffect, useRef, useState } from "react";
import { IconButton, styled } from "@mui/material";
import { FaPause, FaPlay } from "react-icons/fa6";
import { useHover } from "usehooks-ts";


type PlayerContainerProps = {
  backgroundColor?: string;
};

const PlayerContainer = styled("div")<PlayerContainerProps>(({ theme, backgroundColor }) => ({
  display: "flex",
  alignItems: "center",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: backgroundColor || theme.palette.nav.light, // Use the backgroundColor prop or fallback to theme.palette.nav.light
  width: "100%",
  height: "56px",

  "&:hover": {
    backgroundColor: "#3e3a42"
  }
}));

const PlayerImage = styled("img")(({ theme }) => ({
  borderBottomLeftRadius: theme.shape.borderRadius,
  borderTopLeftRadius: theme.shape.borderRadius,
  backgroundColor: "white",
  height: "56px",
  width: "56px"
}));

const PlayerContent = styled("div")(({ theme }) => ({
  marginLeft: "12px",
  width: "100%"
}));

const PlayerButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.nav.light,
  color: theme.palette.nav.main,
  marginRight: "8px",
  "&:hover": {
    transform: "scale(1.1)",
    color: theme.palette.nav.main,
    backgroundColor: theme.palette.ternary.main
  }
}));

function killAudios(audioID: string) {
  document.querySelectorAll(`audio:not([id=${audioID}])`).forEach((el) => {
    const audioElement = el as HTMLAudioElement;
    audioElement.pause();
  });
}

export type Props = {
  audioID: string;
  title: string;
  imageSrc?: string;
  audioSource: string;
  backgroundColor?: string; // Add backgroundColor prop
};

export const AudioPlayer = ({ audioID, title, imageSrc, audioSource, backgroundColor }: Props) => {
  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleTogglePlay = () => {
    const audioPlayer = document.getElementById(audioID) as HTMLAudioElement;

    if (audioPlayer.paused) {
      killAudios(audioID);
      audioPlayer.play();
    } else {
      audioPlayer.pause();
    }

    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    const audioPlayer = document.getElementById(audioID) as HTMLAudioElement;

    const handlePause = () => {
      setIsPlaying(false);
    };

    audioPlayer.addEventListener("pause", handlePause);

    return () => {
      audioPlayer.removeEventListener("pause", handlePause);
    };
  }, [audioID]);

  return (
    <>
      <PlayerContainer ref={hoverRef} backgroundColor={backgroundColor}>
        <PlayerImage src={imageSrc} alt={title} />
        <PlayerContent>{title}</PlayerContent>
        {isHover && (
          <PlayerButton size={"medium"} onClick={handleTogglePlay}>
            {isPlaying ? <FaPause /> : <FaPlay />}
          </PlayerButton>
        )}
      </PlayerContainer>
      <audio src={audioSource} style={{ display: "none" }} controls id={audioID} />
    </>
  );
};

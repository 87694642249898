import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../../global/i18n";
import { Views } from "../../../global/model";
import { Typography, useTheme } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import { Section } from "../../../components/layout/Section";

import HeaderMasteringPicture from "../../../assets/content/music-studio-overview-mastering.jpg";


const Header = styled(Section)(({theme}) => ({
  display: "flex",
  justifyContent: "center",
  paddingTop: theme.spacing(20),

  [theme.breakpoints.down("md")]: {
    paddingTop: 0,
    alignItems: "center"
  }
}));

const Description = styled(Section)(({theme}) => ({
  marginTop: theme.spacing(8)
}));


export const AudioBook = () => {
  const { t } = useTranslation([NAMESPACE.SHARED, Views.AUDIO_BOOK]);
  const theme = useTheme();

  return (
    <>
      <Header bgImage={ HeaderMasteringPicture } header>
        <Typography variant={"h2"} color={theme.palette.text.secondary} fontWeight={"bold"} textAlign={"left"}>
          {t(`${Views.AUDIO_BOOK}:title`)}
        </Typography>
      </Header>
      <Description enableObserver>
        <Typography variant={ "h6" }>
          {t(`${Views.AUDIO_BOOK}:subtitle`)}
        </Typography>
      </Description>
    </>
  );
};

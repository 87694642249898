import React from "react";
import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import Home from "../views/Home";
import { About } from "../views/About";
import { AudioBook, MusicProduction, Podcast, PostProduction, Services } from "../views/Services";
import { Realizations } from "../views/Realizations";
import { Contact } from "../views/Contact";
import { Views } from "../global/model";
import i18n, { NAMESPACE } from "../global/i18n";


const { t } = i18n;

export const Router = createBrowserRouter([
  {
    path: t(`${NAMESPACE.NAVIGATION}:${Views.HOME}.path`),
    element: <App />,
    children: [
      {
        path: t(`${NAMESPACE.NAVIGATION}:${Views.HOME}.path`),
        element: <Home />
      },
      {
        path: t(`${NAMESPACE.NAVIGATION}:${Views.ABOUT}.path`),
        element: <About />
      },
      {
        path: t(`${NAMESPACE.NAVIGATION}:${Views.SERVICES}.path`),
        children: [
          {
            path: t(`${NAMESPACE.NAVIGATION}:${Views.SERVICES}.path`),
            element: <Services />
          },
          {
            path: t(`${NAMESPACE.NAVIGATION}:${Views.SERVICES}.subViews.${Views.MUSIC_PRODUCTION}.path`),
            element: <MusicProduction />
          },
          {
            path: t(`${NAMESPACE.NAVIGATION}:${Views.SERVICES}.subViews.${Views.POST_PRODUCTION}.path`),
            element: <PostProduction />
          },
          {
            path: t(`${NAMESPACE.NAVIGATION}:${Views.SERVICES}.subViews.${Views.PODCAST}.path`),
            element: <Podcast />
          },
          {
            path: t(`${NAMESPACE.NAVIGATION}:${Views.SERVICES}.subViews.${Views.AUDIO_BOOK}.path`),
            element: <AudioBook />
          }
        ]
      },
      {
        path: t(`${NAMESPACE.NAVIGATION}:${Views.REALIZATIONS}.path`),
        element: <Realizations />
      },
      {
        path: t(`${NAMESPACE.NAVIGATION}:${Views.CONTACT}.path`),
        element: <Contact />
      }
    ]
  }
]);

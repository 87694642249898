import React from "react";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { NavbarLink } from "../../../shared/styled";
import { smoothScrollTop } from "../../../shared/utils";
import { FooterNavigationItem } from "../Types";


const Container = styled("div")(({ theme }) => ({
  marginRight: 20,
  marginLeft: 20,
  display: "flex",
  alignItems: "flex-start",
  flexDirection: "column"
}));

const ItemContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column"
}));

type Props = {
  title: string;
  items: FooterNavigationItem[];
};

export const NavigationGroup = ({ title, items }: Props) => {
  return (
    <Container>
      <Typography
        variant={"body1"}
        fontWeight="bold"
        marginBottom={2}
      >
        {title}
      </Typography>
      <ItemContainer>
        {items.map((item) => (
          <Typography key={item.name} variant={"body1"} marginBottom={1}>
            <NavbarLink to={item.path} onClick={() => smoothScrollTop()}>
              {item.name}
            </NavbarLink>
          </Typography>
        ))}
      </ItemContainer>
    </Container>
  );
};

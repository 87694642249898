import React from "react";
import Modal from "react-modal";
import ReactPlayer from "react-player";


interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  videoUrl: string;
}

export const VideoModal = ({ isOpen, onRequestClose, videoUrl }: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.75)" // Background color of the overlay
        },
        content: {
          width: "50%", // Width of the modal content
          height: "50%", // Height of the modal content
          top: "25%", // Position from the top
          left: "25%", // Position from the left
          padding: "0", // Padding inside the modal
          border: "none", // No border
          borderRadius: "8px", // Border radius
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Box shadow
          overflow: "hidden", // Hide overflow
          position: "relative" // Make position relative for absolute positioning of close button
        }
      }}
    >
      {/* Close button */}
      <button
        onClick={onRequestClose}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          backgroundColor: "black",
          border: "none",
          cursor: "pointer",
          fontSize: "24px",
          color: "#fff",
          zIndex: "999" // Ensure the close button appears above the modal
        }}
      >
        X
      </button>
      <ReactPlayer url={videoUrl} controls={true} playing={true} width="100%" height="100%" />
    </Modal>
  );
};
import { Typography, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import React from "react";
import { ObserverProps } from "../Types";
import { ItemPosition, ItemTransition, smoothScrollTop } from "../../../shared/utils";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Transition } from "../../../shared/Transition";


const Content = styled("div")<{ position: ItemPosition }>(({ theme, position }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: position === "left" ? "flex-start" : "flex-end",

  [theme.breakpoints.down("md")]: {
    width: "100%",
    alignItems: "center",
    marginTop: 40
  }
}));

type Props = ObserverProps & {
  position: ItemPosition;
  title: string;
  subtitle: string;
  buttonLabel: string;
  buttonPath: string;
  transition?: ItemTransition;
};

export const OverviewContent = ({
  isVisible,
  position,
  title,
  subtitle,
  buttonLabel,
  buttonPath,
  transition = "none"
}: Props) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const goTo = (path: string) => {
    navigate(path);
    smoothScrollTop();
  };

  return (
    <Transition position={position} transition={transition} isVisible={isVisible}>
      <Content position={position}>
        <Typography
          paddingY={-30}
          color={theme.palette.text.primary}
          fontSize={{ xs: 36, sm: 40, md: 44, lg: 48, xl: 56 }}
          fontWeight="bold"
          textAlign="center"
        >
          {title}
        </Typography>
        <Typography
          paddingY={-30}
          color={theme.palette.text.primary}
          fontSize={{ xs: 20, lg: 24 }}
          textAlign={{ xs: "left", md: position === "right" ? "right" : "left" }}
        >
          {subtitle}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="large"
          sx={{ color: "white", fontWeight: "bold", marginTop: 2 }}
          onClick={() => goTo(buttonPath)}
        >
          {buttonLabel}
        </Button>
      </Content>
    </Transition>
  );
};

import { backendBaseUrl } from "./Api";
import { ContactFormData } from "../global/model";
import { contactFormDataToSendMailResponse } from "./dto/ContactDtoAssembler";
import { SendMailContactFormRequest } from "./dto/SendMailContactFormRequest";


export const sendMailContactForm = async (contactFormData: ContactFormData): Promise<void> => {
  const dto: SendMailContactFormRequest = contactFormDataToSendMailResponse(contactFormData);
  return fetch(`${backendBaseUrl}/contact:sendMail`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(dto) // Convert the data object to a JSON string
  }).then((response) => {
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  });
};

import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../../global/i18n";
import { Views } from "../../../global/model";
import React, { useRef } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { Section } from "../../../components/layout/Section";
import { AudioPlayer } from "../../../components/layout/Audio";

import HeaderStudioPicture from "../../../assets/content/music-studio-overview-editing.jpg";
import SoundBeforeMp3 from "../../../assets/audio/music-studio-view-editing-sound-before.mp3";
import SoundAfterMp3 from "../../../assets/audio/music-studio-view-editing-sound-after.mp3";
import SoundBeforeImage from "../../../assets/logo/sound-equalizer-gray.png";
import SoundAfterImage from "../../../assets/logo/sound-equalizer-secondary.png";


const Header = styled(Section)(({theme}) => ({
  display: "flex",
  justifyContent: "center",
  paddingTop: theme.spacing(20),

  [theme.breakpoints.down("md")]: {
    paddingTop: 0,
    alignItems: "center"
  }
}));

const Description = styled(Section)(({theme}) => ({
  marginTop: theme.spacing(8)
}));

const AudioTracks = styled(Section)(({theme}) => ({
  marginTop: theme.spacing(8),
  maxWidth: "70%",

  [theme.breakpoints.down("md")]: {
    maxWidth: "none",
  }
}));


export const PostProduction = () => {
  const { t } = useTranslation([NAMESPACE.SHARED, Views.POST_PRODUCTION]);
  const theme = useTheme();
  const audioRef = useRef(new Audio(SoundBeforeMp3));

  return (
    <>
      <Header bgImage={ HeaderStudioPicture } header>
        <Typography variant={"h2"} color={theme.palette.text.secondary} fontWeight={"bold"} textAlign={"left"}>
          {t(`${Views.POST_PRODUCTION}:title`)}
        </Typography>
      </Header>
      <Description enableObserver>
        <Typography variant={ "h6" }>
          {t(`${Views.POST_PRODUCTION}:subtitle`)}
        </Typography>
      </Description>
      <AudioTracks bgColor={theme.palette.secondary.light}>
        <Typography variant={"h4"} fontWeight={"bold"} marginBottom={2} textAlign={"center"}>
          {t(`${NAMESPACE.SHARED}:audioSample`)}
        </Typography>
        <div>
          <img src={SoundBeforeImage} style={{ maxWidth: "20%", display: "block", margin: "auto" }} />
          <AudioPlayer
            audioID={"before"}
            title={t(`${NAMESPACE.SHARED}:before`)}
            audioSource={SoundBeforeMp3}
            imageSrc={"https://m.media-amazon.com/images/I/71BMNK4yhoL._AC_SL1440_.jpg"}
            backgroundColor={theme.palette.secondary.light}
          />
        </div>
        <div>
          <img src={SoundAfterImage} style={{ maxWidth: "20%", display: "block", margin: "auto" }} />
          <AudioPlayer
            audioID={"after"}
            title={t(`${NAMESPACE.SHARED}:after`)}
            audioSource={SoundAfterMp3}
            imageSrc={"https://m.media-amazon.com/images/I/71BMNK4yhoL._AC_SL1440_.jpg"}
            backgroundColor={theme.palette.secondary.light}
          />
        </div>
      </AudioTracks>
    </>
  );
};

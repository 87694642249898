import ArchiveIcon from "@mui/icons-material/Archive";
import React from "react";
import i18n, { NAMESPACE } from "../../../global/i18n";
import { Views } from "../../../global/model";
import { FaRecordVinyl } from "react-icons/fa";
import {
  FaAddressCard,
  FaClipboardQuestion,
  FaHeadphones,
  FaIcons,
  FaJediOrder,
  FaMix,
  FaPenNib
} from "react-icons/fa6";


export type NavigationMetadata = {
  [Views.HOME]: NavigationItem;
  [Views.ABOUT]: NavigationItem;
  [Views.SERVICES]: NavigationItemGroup;
  [Views.REALIZATIONS]: NavigationItem;
  [Views.CONTACT]: NavigationItem;
};

export type NavigationItemGroup = {
  root: NavigationItem;
  items: NavigationItem[];
};

export type NavigationItem = {
  name: string;
  path: string;
  icon: JSX.Element;
};

const { t } = i18n;

export const navbarMetadata: NavigationMetadata = {
  [Views.HOME]: {
    name: t(`${NAMESPACE.NAVIGATION}:${Views.HOME}.name`),
    path: t(`${NAMESPACE.NAVIGATION}:${Views.HOME}.path`),
    icon: <ArchiveIcon sx={{ color: "text.primary" }} />
  },
  [Views.ABOUT]: {
    name: t(`${NAMESPACE.NAVIGATION}:${Views.ABOUT}.name`),
    path: t(`${NAMESPACE.NAVIGATION}:${Views.ABOUT}.path`),
    icon: <FaClipboardQuestion style={{ marginRight: 12 }} />
  },
  [Views.SERVICES]: {
    root: {
      name: t(`${NAMESPACE.NAVIGATION}:${Views.SERVICES}.name`),
      path: t(`${NAMESPACE.NAVIGATION}:${Views.SERVICES}.path`),
      icon: <FaJediOrder style={{ marginRight: 12 }} />
    },
    items: [
      {
        name: t(`${NAMESPACE.NAVIGATION}:${Views.SERVICES}.subViews.${Views.MUSIC_PRODUCTION}.name`),
        path: t(`${NAMESPACE.NAVIGATION}:${Views.SERVICES}.subViews.${Views.MUSIC_PRODUCTION}.path`),
        icon: <FaRecordVinyl style={{ marginRight: 12 }} />
      },
      {
        name: t(`${NAMESPACE.NAVIGATION}:${Views.SERVICES}.subViews.${Views.POST_PRODUCTION}.name`),
        path: t(`${NAMESPACE.NAVIGATION}:${Views.SERVICES}.subViews.${Views.POST_PRODUCTION}.path`),
        icon: <FaPenNib style={{ marginRight: 12 }} />
      },
      {
        name: t(`${NAMESPACE.NAVIGATION}:${Views.SERVICES}.subViews.${Views.PODCAST}.name`),
        path: t(`${NAMESPACE.NAVIGATION}:${Views.SERVICES}.subViews.${Views.PODCAST}.path`),
        icon: <FaMix style={{ marginRight: 12 }} />
      },
      {
        name: t(`${NAMESPACE.NAVIGATION}:${Views.SERVICES}.subViews.${Views.AUDIO_BOOK}.name`),
        path: t(`${NAMESPACE.NAVIGATION}:${Views.SERVICES}.subViews.${Views.AUDIO_BOOK}.path`),
        icon: <FaHeadphones style={{ marginRight: 12 }} />
      }
    ]
  },
  [Views.REALIZATIONS]: {
    name: t(`${NAMESPACE.NAVIGATION}:${Views.REALIZATIONS}.name`),
    path: t(`${NAMESPACE.NAVIGATION}:${Views.REALIZATIONS}.path`),
    icon: <FaIcons style={{ marginRight: 12 }} />
  },
  [Views.CONTACT]: {
    name: t(`${NAMESPACE.NAVIGATION}:${Views.CONTACT}.name`),
    path: t(`${NAMESPACE.NAVIGATION}:${Views.CONTACT}.path`),
    icon: <FaAddressCard style={{ marginRight: 12 }} />
  }
};

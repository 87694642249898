import React, { useState } from "react";
import { Divider, Drawer, IconButton, List } from "@mui/material";
import { styled } from "@mui/material/styles";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { NavigationMetadata } from "../metadata";
import { SliderMenu } from "./SliderMenu";
import SliderItem from "./SliderItem";
import { smoothScrollTop } from "../../../shared/utils";
import { Views } from "../../../../global/model";


const Header = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start"
}));

type Props = {
  state: boolean;
  toggle: (open: boolean, event: React.KeyboardEvent | React.MouseEvent) => void;
  data: NavigationMetadata;
};

const Slider = ({ state, toggle, data }: Props) => {
  const [servicesOpened, setServicesOpened] = useState<boolean>(false);
  const [postProductionOpened, setPostProductionOpened] = useState<boolean>(false);
  const [realizationsOpened, setRealizationsOpened] = useState<boolean>(false);

  const closeAll = (event: React.KeyboardEvent | React.MouseEvent) => {
    toggle(false, event);
    setServicesOpened(false);
    setPostProductionOpened(false);
    setRealizationsOpened(false);
    smoothScrollTop();
  };

  return (
    <Drawer
      anchor="right"
      open={state}
      onClose={closeAll}
      PaperProps={{
        sx: {
          backgroundColor: "nav.main",
          minWidth: "80%"
        }
      }}
    >
      <Header>
        <IconButton onClick={closeAll}>
          <ChevronRightIcon sx={{ color: "text.primary" }} />
        </IconButton>
      </Header>
      <Divider />
      <List>
        <SliderItem item={data.about} closeAll={closeAll} disablePadding={true} />
        <SliderMenu
          data={data[Views.SERVICES]}
          closeAll={closeAll}
          isExpand={servicesOpened}
          toggleExpand={setServicesOpened}
        />
        <SliderItem item={data[Views.REALIZATIONS]} closeAll={closeAll} disablePadding={true} />
        <SliderItem item={data[Views.CONTACT]} closeAll={closeAll} disablePadding={true} />
      </List>
    </Drawer>
  );
};

export default Slider;

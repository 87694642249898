import React, { useState } from "react";
import ReactCardFlip from "react-card-flip";
import { styled } from "@mui/material";
import SoundIcon from "../../../../assets/content/realizations-picturetosound-playicon.svg";
import { VideoModal } from "./Items/VideoModal";


const ProjectCard = styled("div")({
  width: "400px",
  height: "250px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "10px",
  cursor: "pointer",
  overflow: "hidden",
  transition: "transform 0.5s"
});

const CardBack = styled("div")({
  color: "#333",
  backgroundColor: "#fff",
  border: "1px solid #ccc",
  height: "100%", // Set the height to 100% to take full height of the parent
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px", // Add padding for better aesthetics
  boxSizing: "border-box", // Include padding and border in the total height
  position: "relative" // Position relative for absolute positioning of play button
});

const PlayButton = styled("div")({
  position: "absolute",
  top: "10%", // Adjust the bottom position in pixels
  left: "50%", // Adjust the left position in pixels
  transform: "translate(-50%, -50%)",
  cursor: "pointer"
});

const ProjectImage = styled("img")({
  maxWidth: "100%",
  maxHeight: "100%"
});

const CardFront = styled("div")({
  cursor: "pointer"
});

type Props = {
  imageUrl: string;
  name: string;
  description: string;
  videoUrl: string;
};

export const FlipCard = ({ imageUrl, name, description, videoUrl } : Props) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);

  const handleMouseEnter = () => {
    setIsFlipped(true);
  };

  const handleMouseLeave = () => {
    setIsFlipped(false);
  };

  const handleCardClick = () => {
    setShowVideoModal(true);
  };

  const handleCloseVideoModal = () => {
    setShowVideoModal(false);
  };

  const iconStyle = {
    width: "40px", // Adjust the width
    height: "40px" // Adjust the height
  };

  return (
    <>
      <ProjectCard onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleCardClick}>
        <ReactCardFlip isFlipped={isFlipped} flipDirection="vertical">
          {/* Front side */}
          <CardFront>
            <ProjectImage src={imageUrl} alt={name} />
          </CardFront>
          {/* Back side */}
          <CardBack>
            {/* Play icon */}
            <PlayButton>
              <img src={SoundIcon} style={{ height: 40 }} alt="LUFS logo" />
            </PlayButton>
            <h2>{name}</h2>
            <p>{description}</p>
          </CardBack>
        </ReactCardFlip>
      </ProjectCard>
      {/* Video Modal */}
      {showVideoModal && (
        <VideoModal key={videoUrl} isOpen={showVideoModal} onRequestClose={handleCloseVideoModal} videoUrl={videoUrl} />
      )}
    </>
  );
};
export const home = {
  header: {
    title: "BRISER LE MUR DU SON",
    subtitle: "Réservez votre session chez Studio Balance dès maintenant et donnez vie à vos idées les plus créatives.",
  },
  clients: {
    title: "Clients qui font confiance à Studio Balance !",
  },
  comments: {
    title: "Commentaires de clients",
    clients: {
      first : {
        name: "Katia Curadeau Joncas",
        title: "Réalisatrice",
        comment: `« Chaque fois que j’ai besoin de l’aide d’un pro pour rendre le son de mes projets au top, c’est 
          définitivement Laurent qui me livre les meilleurs résultats! Il a beaucoup d’expérience, une belle écoute et 
          de la créativité à la tonne. Vous ne trouverez pas mieux comme service! »`
      },
      second : {
        name: "Mamoun Karmouchi",
        title: "Manager du collectif 4LOVÉ",
        comment: `« Laurent, notre génie du son ! Depuis un an, il sublime nos chansons avec son talent inégalé. 
          Enregistrement, mixage, mastering, il excelle dans chaque étape. Laurent comprend notre vision artistique à la 
          perfection. Ses précieux conseils élèvent notre musique vers de nouveaux sommets. »`
      },
      third : {
        name: "Félix Gosselin",
        title: "Réalisateur et producteur",
        comment: `« Laurent a apporté ma production à un autre niveau par son mix audio. Il a su livrer la marchandise 
          en s'adaptant à des délais difficiles, tout en fournissant un service client et une rapidité de réponse 
          impeccables. »`
      },
      fourth : {
        name: "Phénomène Kiyangi (Pheno)",
        title: "Artiste indépendant",
        comment: `« Je suis toujours très satisfait de bosser avec le Studio Balance. Mis à part son boulot d’ingénieur 
          son, il demeure un vrai créateur qui propose une bonne direction artistique. »`
      }
    }
  }
}
import React from "react";
import { NAMESPACE } from "../../global/i18n";
import { Views } from "../../global/model";
import { styled, Typography } from "@mui/material";
import { AudioPlayer } from "../../components/layout/Audio";
import { FlipCard } from "../../components/layout/Card/FlipCard";
import { useTranslation } from "react-i18next";
import { Section } from "../../components/layout/Section";
import { useTheme } from "@mui/material/styles";

import RealizationImage from "../../assets/content/realizations-image.jpg";
import ImageUrl1 from "../../assets/content/realizations-picturetosound-projet1.jpg";
import soundUrl1 from "../../assets/audio/realization-music-01-dans-les-etoiles.mp3";
import ImageUrl2 from "../../assets/content/realizations-picturetosound-projet2.jpg";
import soundUrl2 from "../../assets/audio/realization-music-02-les-plus-beaux-souvenirs.mp3";
import ImageUrl3 from "../../assets/content/realizations-music-projet3.jpg";
import soundUrl3 from "../../assets/audio/realization-music-03-100-pas.mp3";
import ImageUrl4 from "../../assets/content/realizations-music-projet4.jpg";
import soundUrl4 from "../../assets/audio/realization-music-04-condamne-medha.mp3";
import ImageUrl5 from "../../assets/content/realizations-picturetosound-projet5.jpg";
import soundUrl5 from "../../assets/audio/realization-music-05-corde-raide.mp3";
import ImageUrl6 from "../../assets/content/realizations-picturetosound-projet6.jpg";
import ImageUrl7 from "../../assets/content/realizations-picturetosound-projet6.jpg";
import soundUrl6 from "../../assets/audio/realization-music-06-loved-and-broken.mp3";
import soundUrl7 from "../../assets/audio/realization-music-07-stay.mp3";
import ImageUrl8 from "../../assets/content/realizations-music-projet8.jpg";
import soundUrl8 from "../../assets/audio/realization-music-08-vivre-comme-ca.mp3";
import ImageUrl9 from "../../assets/content/realizations-music-projet9.jpg";
import soundUrl9 from "../../assets/audio/realization-music-09-wont-waste-another-day-charly-blue.mp3";


const VideoUrl1 = "https://www.youtube.com/watch?v=FrpvZF4VkfY";
const VideoUrl2 = "https://vimeo.com/823076311";
const VideoUrl3 = "https://vimeo.com/832390782";
const VideoUrl4 = "https://www.youtube.com/watch?v=_6vCOwTUp1A";
const VideoUrl5 = "/Videos/realizations-picturetosound-projet5.mp4";


const Header = styled(Section)(({theme}) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const Music = styled(Section)(({theme}) => ({
  marginTop: theme.spacing(8),
}));

const Sounds = styled("div")(({ theme }) => ({
  display: "grid",
  gridGap: "12px",
  gridTemplateColumns: "repeat(3, 1fr)",

  [theme.breakpoints.down("lg")]: {
    gridTemplateColumns: "repeat(2, 1fr)"
  },

  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "repeat(1, 1fr)"
  }
}));

const Portfolio = styled(Section)(({theme}) => ({
  marginTop: theme.spacing(8),
}));

const Projects = styled("div")({
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
  gap: "20px"
});


export const Realizations = () => {
  const { t } = useTranslation([NAMESPACE.NAVIGATION, NAMESPACE.SHARED, Views.REALIZATIONS]);
  const theme = useTheme();

  const sounds = [
    {
      imageUrl: ImageUrl1,
      soundUrl: soundUrl1,
      name: t(`${Views.REALIZATIONS}:music.sounds.sound1.title`),
      description: t(`${Views.REALIZATIONS}:music.sounds.sound1.description`)
    },
    {
      imageUrl: ImageUrl2,
      soundUrl: soundUrl2,
      name: t(`${Views.REALIZATIONS}:music.sounds.sound2.title`),
      description: t(`${Views.REALIZATIONS}:music.sounds.sound2.description`)
    },
    {
      imageUrl: ImageUrl3,
      soundUrl: soundUrl3,
      name: t(`${Views.REALIZATIONS}:music.sounds.sound3.title`),
      description: t(`${Views.REALIZATIONS}:music.sounds.sound3.description`)
    },
    {
      imageUrl: ImageUrl4,
      soundUrl: soundUrl4,
      name: t(`${Views.REALIZATIONS}:music.sounds.sound4.title`),
      description: t(`${Views.REALIZATIONS}:music.sounds.sound4.description`)
    },
    {
      imageUrl: ImageUrl5,
      soundUrl: soundUrl5,
      name: t(`${Views.REALIZATIONS}:music.sounds.sound5.title`),
      description: t(`${Views.REALIZATIONS}:music.sounds.sound5.description`)
    },
    {
      imageUrl: ImageUrl6,
      soundUrl: soundUrl6,
      name: t(`${Views.REALIZATIONS}:music.sounds.sound6.title`),
      description: t(`${Views.REALIZATIONS}:music.sounds.sound6.description`)
    },
    {
      imageUrl: ImageUrl7,
      soundUrl: soundUrl7,
      name: t(`${Views.REALIZATIONS}:music.sounds.sound7.title`),
      description: t(`${Views.REALIZATIONS}:music.sounds.sound7.description`)
    },
    {
      imageUrl: ImageUrl8,
      soundUrl: soundUrl8,
      name: t(`${Views.REALIZATIONS}:music.sounds.sound8.title`),
      description: t(`${Views.REALIZATIONS}:music.sounds.sound8.description`)
    },
    {
      imageUrl: ImageUrl9,
      soundUrl: soundUrl9,
      name: t(`${Views.REALIZATIONS}:music.sounds.sound9.title`),
      description: t(`${Views.REALIZATIONS}:music.sounds.sound9.description`)
    }
  ];

  const projects = [
    {
      imageUrl: ImageUrl1,
      videoUrl: VideoUrl1,
      name: t(`${Views.REALIZATIONS}:portfolio.projects.project1.title`),
      description: t(`${Views.REALIZATIONS}:portfolio.projects.project1.description`)
    },
    {
      imageUrl: ImageUrl2,
      videoUrl: VideoUrl2,
      name: t(`${Views.REALIZATIONS}:portfolio.projects.project2.title`),
      description: t(`${Views.REALIZATIONS}:portfolio.projects.project2.description`)
    },
    {
      imageUrl: ImageUrl3,
      videoUrl: VideoUrl3,
      name: t(`${Views.REALIZATIONS}:portfolio.projects.project3.title`),
      description: t(`${Views.REALIZATIONS}:portfolio.projects.project3.description`)
    },
    {
      imageUrl: ImageUrl4,
      videoUrl: VideoUrl4,
      name: t(`${Views.REALIZATIONS}:portfolio.projects.project4.title`),
      description: t(`${Views.REALIZATIONS}:portfolio.projects.project4.description`)
    },
    {
      imageUrl: ImageUrl5,
      videoUrl: VideoUrl5,
      name: t(`${Views.REALIZATIONS}:portfolio.projects.project5.title`),
      description: t(`${Views.REALIZATIONS}:portfolio.projects.project5.description`)
    }
  ];

  return (
    <>
      <Header bgImage={ RealizationImage } header>
        <Typography variant={"h2"} color={theme.palette.text.secondary} fontWeight={"bold"} textAlign={"left"}>
          {t(`${Views.REALIZATIONS}:header.title`)}
        </Typography>
      </Header>
      <Music>
        <Typography variant={"h4"} fontWeight={"bold"} textAlign={"left"} marginBottom={4}>
          {t(`${Views.REALIZATIONS}:music.title`)}
        </Typography>
        <Sounds>
          {sounds.map((sound, index) => (
            <AudioPlayer
              key={`audio${index}`} // Assign unique key prop
              audioID={`audio${index}`}
              title={sound.name}
              imageSrc={sound.imageUrl}
              audioSource={sound.soundUrl}
            />
          ))}
        </Sounds>
      </Music>
      <Portfolio>
        <Typography variant={"h4"} fontWeight={"bold"} textAlign={"left"}>
          {t(`${Views.REALIZATIONS}:portfolio.title`)}
        </Typography>
        <Typography variant={"h4"} fontWeight={"bold"} textAlign={"center"} marginBottom={4}>
          {t(`${Views.REALIZATIONS}:portfolio.subtitle`)}
        </Typography>
        <Projects>
          {projects.map((Project, index) => (
            <FlipCard
              key={index}
              imageUrl={Project.imageUrl}
              name={Project.name}
              description={Project.description}
              videoUrl={Project.videoUrl}
            />
          ))}
        </Projects>
        <div id="video-container"></div>
      </Portfolio>
    </>
  );
};

import { SendMailContactFormRequest } from "./SendMailContactFormRequest";
import { ContactFormData } from "../../global/model";


export const contactFormDataToSendMailResponse = (data: ContactFormData): SendMailContactFormRequest => {
  return {
    clientName: data.name,
    clientEmail: data.email,
    subject: data.subject,
    message: data.message
  };
};

import { useContext } from "react";
import { ToastContext } from "../../global/contexts/Toast";
import { ContextType } from "../../global/contexts/Toast/ToastContext";


export const useToast = (): ContextType => {
  const context: ContextType = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};

export enum Views {
  HOME = "home",
  ABOUT = "about",
  SERVICES = "services",
  MUSIC_PRODUCTION = "musicProduction",
  POST_PRODUCTION = "postProduction",
  PODCAST = "podcast",
  AUDIO_BOOK = "audioBook",
  REALIZATIONS = "realizations",
  MUSIC = "music",
  PICTURE_TO_SOUND = "pictureToSound",
  CONTACT = "contact",
}

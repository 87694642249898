import React from "react";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../global/i18n";
import { Typography, useTheme } from "@mui/material";
import { Views } from "../../global/model";
import { OverviewContent, OverviewImage, Section } from "../../components/layout/Section";
import { styled } from "@mui/material/styles";
import { ItemPosition } from "../../components/shared/utils";

import HeaderServicesPicture from "../../assets/content/music-studio-overview-header.jpg";
import RecordingServicesPicture from "../../assets/content/music-studio-overview-recording.jpg";
import EditingServicesPicture from "../../assets/content/music-studio-overview-editing.jpg";
import MixingServicesPicture from "../../assets/content/music-studio-overview-mixing.jpg";
import MasteringServicesPicture from "../../assets/content/music-studio-overview-mastering.jpg";


const Header = styled(Section)(({ theme }) => ({
  paddingTop: theme.spacing(20),

  [theme.breakpoints.down("md")]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 0
  }
}));

const Overview = styled(Section)<{ position: ItemPosition }>(({ theme, position }) => ({
  display: "flex",
  flexDirection: position === "left" ? "row-reverse" : "row",
  justifyContent: "space-between",
  alignItems: "center",

  [theme.breakpoints.down("md")]: {
    width: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },

  [theme.breakpoints.down("sm")]: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  }
}));
 
export const Services = () => {
  const { t } = useTranslation([NAMESPACE.NAVIGATION, NAMESPACE.SHARED, Views.SERVICES]);
  const theme = useTheme();

  return (
    <>
      <Header bgImage={HeaderServicesPicture} header>
        <Typography variant={"h2"} color={theme.palette.text.secondary} fontWeight="bold" textAlign="left">
          {t(`${Views.SERVICES}:header.title`)}
        </Typography>
      </Header>
      <Overview minHeight={"100vh"} bgColor={theme.palette.nav.light} position={"right"} enableObserver>
        <OverviewImage
          position={"left"}
          transition={"fade"}
          src={RecordingServicesPicture}
        />
        <OverviewContent
          position={"right"}
          title={t(`${Views.SERVICES}:${Views.MUSIC_PRODUCTION}.title`)}
          subtitle={t(`${Views.SERVICES}:${Views.MUSIC_PRODUCTION}.subtitle`)}
          buttonLabel={t(`${NAMESPACE.SHARED}:learnMore`)}
          buttonPath={t(`${NAMESPACE.NAVIGATION}:${Views.SERVICES}.subViews.${Views.MUSIC_PRODUCTION}.path`)}
          transition={"slide"}
        />
      </Overview>
      <Overview minHeight={"100vh"} bgColor={theme.palette.nav.main} position={"left"} enableObserver>
        <OverviewImage
          position={"right"}
          transition={"fade"}
          src={EditingServicesPicture}
        />
        <OverviewContent
          position={"left"}
          title={t(`${Views.SERVICES}:${Views.POST_PRODUCTION}.title`)}
          subtitle={t(`${Views.SERVICES}:${Views.POST_PRODUCTION}.subtitle`)}
          buttonLabel={t(`${NAMESPACE.SHARED}:learnMore`)}
          buttonPath={t(`${NAMESPACE.NAVIGATION}:${Views.SERVICES}.subViews.${Views.POST_PRODUCTION}.path`)}
          transition={"slide"}
        />
      </Overview>
      <Overview minHeight={"100vh"} bgColor={theme.palette.nav.light} position={"right"} enableObserver>
        <OverviewImage
          position={"left"}
          transition={"fade"}
          src={MixingServicesPicture}
        />
        <OverviewContent
          position={"right"}
          title={t(`${Views.SERVICES}:${Views.PODCAST}.title`)}
          subtitle={t(`${Views.SERVICES}:${Views.PODCAST}.subtitle`)}
          buttonLabel={t(`${NAMESPACE.SHARED}:learnMore`)}
          buttonPath={t(`${NAMESPACE.NAVIGATION}:${Views.SERVICES}.subViews.${Views.PODCAST}.path`)}
          transition={"slide"}
        />
      </Overview>
      <Overview minHeight={"100vh"} bgColor={theme.palette.nav.main} position={"left"} enableObserver>
        <OverviewImage
          position={"right"}
          transition={"fade"}
          src={MasteringServicesPicture}
        />
        <OverviewContent
          position={"left"}
          title={t(`${Views.SERVICES}:${Views.AUDIO_BOOK}.title`)}
          subtitle={t(`${Views.SERVICES}:${Views.AUDIO_BOOK}.subtitle`)}
          buttonLabel={t(`${NAMESPACE.SHARED}:learnMore`)}
          buttonPath={t(`${NAMESPACE.NAVIGATION}:${Views.SERVICES}.subViews.${Views.AUDIO_BOOK}.path`)}
          transition={"slide"}
        />
      </Overview>
    </>
  );
};

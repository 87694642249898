import { Collapse, Divider } from "@mui/material";
import React from "react";
import { NavigationItemGroup } from "../metadata";
import SliderItem from "./SliderItem";


type Props = {
  data: NavigationItemGroup;
  closeAll: (event: React.KeyboardEvent | React.MouseEvent) => void;
  isExpand: boolean;
  toggleExpand?: (expand: boolean) => void;
};

export const SliderMenu = ({ data, closeAll, isExpand, toggleExpand }: Props) => {
  return (
    <>
      <SliderItem item={data.root} closeAll={closeAll} disablePadding={true} isExpand={isExpand} toggleExpand={toggleExpand} />
      <Collapse in={isExpand} timeout="auto" sx={{ bgcolor: "nav.light" }} unmountOnExit>
        {data.items.map((item, index) => (
          <SliderItem key={index} item={item} closeAll={closeAll} disablePadding={false} />
        ))}
      </Collapse>
      <Divider />
    </>
  );
};

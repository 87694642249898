import { default as MuiHoverMenu } from "material-ui-popup-state/HoverMenu";
import { bindMenu, usePopupState } from "material-ui-popup-state/hooks";
import { MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Button } from "./Button";
import { NavigationItemGroup } from "../metadata";
import { NavbarLink } from "../../../shared/styled";
import { smoothScrollTop } from "../../../shared/utils";


const Menu = styled(MuiHoverMenu)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    minWidth: 180,
    //marginTop: theme.spacing(-0.5),
    backgroundColor: theme.palette.nav.light,
    color: theme.palette.mode === "light" ? theme.palette.text.primary : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0"
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.primary,
        marginRight: theme.spacing(1.5)
      },
      "&:hover": {
        backgroundColor: theme.palette.background.default
      },
      "&:active": {
        backgroundColor: (theme.palette.ternary.light, theme.palette.action.selectedOpacity)
      }
    }
  }
}));

type Props = {
  data: NavigationItemGroup;
};

export const HoverMenu = ({ data }: Props) => {
  const popupState = usePopupState({
    variant: "popover",
    popupId: "demoMenu"
  });

  const closeMenu = (event: React.MouseEvent<HTMLElement>) => {
    popupState.setOpen(!popupState.isOpen, event);
  };

  return (
    <>
      <Button
        onClick={closeMenu}
        path={data.root.path}
        popupState={popupState}
        endIcon={<KeyboardArrowDownIcon color="primary" />}
      >
        {data.root.name}
      </Button>
      <Menu
        {...bindMenu(popupState)}
        elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        {data.items.map((item) => (
          <MenuItem key={item.name} onClick={closeMenu} disableRipple>
            <NavbarLink sx={{ width: "100%" }} to={item.path} onClick={() => smoothScrollTop()}>
              {item.icon}
              {item.name}
            </NavbarLink>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

import React from "react";
import { styled } from "@mui/material/styles";
import { NavigationGroup } from "./NavigationGroup";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../../../global/i18n";
import { Views } from "../../../../global/model";
import { FooterNavigation } from "../Types";


const NavigationContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  width: "50%",
  [theme.breakpoints.down("md")]: {
    marginTop: 50,
    width: "100%"
  }
}));

export const Navigation = () => {
  const {t} = useTranslation([NAMESPACE.FOOTER, NAMESPACE.NAVIGATION]);

  const footerMetadata: FooterNavigation = {
    products: {
      name: t(`${NAMESPACE.NAVIGATION}:${Views.SERVICES}.name`),
      items: [
        {
          name: t(`${NAMESPACE.NAVIGATION}:${Views.SERVICES}.subViews.${Views.MUSIC_PRODUCTION}.name`),
          path: t(`${NAMESPACE.NAVIGATION}:${Views.SERVICES}.subViews.${Views.MUSIC_PRODUCTION}.path`)
        },
        {
          name: t(`${NAMESPACE.NAVIGATION}:${Views.SERVICES}.subViews.${Views.POST_PRODUCTION}.name`),
          path: t(`${NAMESPACE.NAVIGATION}:${Views.SERVICES}.subViews.${Views.POST_PRODUCTION}.path`)
        },
        {
          name: t(`${NAMESPACE.NAVIGATION}:${Views.SERVICES}.subViews.${Views.PODCAST}.name`),
          path: t(`${NAMESPACE.NAVIGATION}:${Views.SERVICES}.subViews.${Views.PODCAST}.path`)
        },
        {
          name: t(`${NAMESPACE.NAVIGATION}:${Views.SERVICES}.subViews.${Views.AUDIO_BOOK}.name`),
          path: t(`${NAMESPACE.NAVIGATION}:${Views.SERVICES}.subViews.${Views.AUDIO_BOOK}.path`)
        }
      ]
    },
    company: {
      name: t(`${NAMESPACE.FOOTER}:navigation.studio.name`),
      items: [
        {
          name: t(`${NAMESPACE.NAVIGATION}:${Views.ABOUT}.name`),
          path: t(`${NAMESPACE.NAVIGATION}:${Views.ABOUT}.path`)
        },
        {
          name: t(`${NAMESPACE.NAVIGATION}:${Views.REALIZATIONS}.name`),
          path: t(`${NAMESPACE.NAVIGATION}:${Views.REALIZATIONS}.path`)
        },
        {
          name: t(`${NAMESPACE.NAVIGATION}:${Views.CONTACT}.name`),
          path: t(`${NAMESPACE.NAVIGATION}:${Views.CONTACT}.path`)
        }
      ]
    }
  };

  return (
    <NavigationContainer>
      <NavigationGroup title={footerMetadata.products.name} items={footerMetadata.products.items} />
      <NavigationGroup title={footerMetadata.company.name} items={footerMetadata.company.items} />
    </NavigationContainer>
  );
};

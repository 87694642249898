import { styled } from "@mui/material/styles";
import { useEffect } from "react";
import { motion, useAnimate, useMotionValue } from "framer-motion";


const Container = styled("div")<{ height: string }>(({ height }) => ({
  position: "relative",
  height: height,
  width: "100%",
  overflow: "hidden"
}));

const Mask = styled("div")<{ height: string, color: string }>(({ height, color }) => ({
  position: "absolute",
  zIndex: 2,
  height: height,
  width: "100%",
  background: `linear-gradient(90deg, ${color} 0%, rgba(255,255,255,0) 25%, rgba(255,255,255,0) 50%, rgba(255,255,255,0) 75%, ${color} 100%)`,
  pointerEvents: "none",
}));

const Carousel = styled(motion.div)<{ count: number, itemwidth: number }>(({  count, itemwidth }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: `${itemwidth * count}px`,
}));

const Item = styled("img")(() => ({
  zIndex: 1,
  width: "auto",
  objectFit: "contain",
}));

const calculateBuffer = (count: number) => {
  if (count <= 6) {
    return 40;
  } else if (count <= 8) {
    return 36;
  } else {
    return 30;
  }
}

type Props = {
  items: { src: string, alt: string }[];
  height: string;
  color: string;
  duration?: number;
  itemWidth?: number;
}

export const RollingCarousel = ({items, height, color, duration = 20, itemWidth = 250}: Props) => {
  const [scope, animate] = useAnimate();
  const xTranslation = useMotionValue(0);
  let width;

  useEffect(() => {
    width = scope.current?.offsetWidth
    let position = -width / 2 - calculateBuffer(items.length);

    let control = animate(xTranslation, [0, position], {
      ease: "linear",
      duration: duration,
      repeat: Infinity,
      repeatType: "loop",
      repeatDelay: 0,
    })
    return control.stop;

  },[xTranslation, width])

  return (
    <Container height={height}>
      <Mask height={height} color={color} />
      <Carousel ref={scope} style={{x: xTranslation}} count={items.length * 2} itemwidth={itemWidth}>
        {[...items, ...items].map((item, index) => (
          <Item key={index} src={item.src} alt={item.alt} height={height} />
        ))}
      </Carousel>
    </Container>
  )
}

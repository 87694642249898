import { styled } from "@mui/material/styles";
import { NavLink } from "react-router-dom";


export const NavbarLink = styled(NavLink)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  textDecoration: "inherit",
  color: theme.palette.text.primary
}));

export const FlexDiv = styled("div")`
  display: flex;
`;

export const SpaceBetweenCenterDiv = styled(FlexDiv)`
  align-items: center;
  justify-content: space-between;
`;

export const CenteredBackgroundImage = styled("img")<{ header?: boolean }>(({ theme, header = false }) => ({
  zIndex: -1,
  //    position: 'absolute',
  height: header ? `calc(100vh - 56px)` : "100vh",
  width: "100%",
  overflow: "hidden",
  objectFit: "cover",
  backgroundPosition: "center center"
}));

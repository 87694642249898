import React from "react";
import { ContactForm } from "../../components/layout/Form/ContactForm";
import { styled, useTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { NAMESPACE } from "../../global/i18n";
import { Views } from "../../global/model";
import { useTranslation } from "react-i18next";
import { Section } from "../../components/layout/Section";


const Coordinates = styled(Section)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: theme.spacing(8),

  [theme.breakpoints.down("md")]: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  }
}));

const Map = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    marginBottom: theme.spacing(4),
  }
}));

const Email = styled("div")(({ theme }) => ({
}));

const Form = styled(Section)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginTop: theme.spacing(8),
  marginBottom: theme.spacing(8)
}));

export const Contact = () => {
  const { t } = useTranslation([NAMESPACE.SHARED, Views.CONTACT]);
  const theme = useTheme();

  return (
    <>
      <Coordinates>
        <Map>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2730.4692777510836!2d-71.22752606395173!3d46.81475936673252!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cb896723246cca9%3A0xf33af4ec3423680d!2s363%20Rue%20de%20la%20Couronne%20local%20201%2C%20Qu%C3%A9bec%2C%20QC%20G1K%206E9!5e0!3m2!1sfr!2sca!4v1707784091374!5m2!1sfr!2sca"
            width={400}
            height={300}
            style={{ border: "0" }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </Map>
        <Email>
          <Typography variant={"h4"} fontWeight="bold" textAlign="center">
            {t(`${Views.CONTACT}:form.email`)}
          </Typography>
          <Typography variant={"h4"} color={theme.palette.primary.light} fontWeight="bold" textAlign="center">
            {t(`${Views.CONTACT}:form.telephone`)}
          </Typography>
        </Email>
      </Coordinates>
      <Form>
        <Typography fontSize={{ xs: 32, md: 48 }} fontWeight="bold" textAlign="center">
          {t(`${Views.CONTACT}:form.title`)}
        </Typography>
        <ContactForm />
      </Form>
    </>
  );
};

import { createContext } from "react";


export type ContextType = {
  openToast: (message: string, severity: string) => void;
  closeToast: () => void;
  toast: Toast;
};

export type Toast = {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error";
};

export const ToastContext = createContext<ContextType>({
  openToast: (): void => {},
  closeToast: (): void => {},
  toast: {
    open: false,
    message: "",
    severity: "success"
  }
});

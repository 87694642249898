import React from "react";
import { IconButton, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { NavbarLink, SpaceBetweenCenterDiv } from "../../../shared/styled";
import { NavigationItem } from "../metadata";
import { styled } from "@mui/material/styles";


const MarginedButton = styled(IconButton)`
  margin-left: 8px;
  margin-right: 8px;
`;

type Props = {
  item: NavigationItem;
  closeAll: (event: React.KeyboardEvent | React.MouseEvent) => void;
  disablePadding: boolean;
  isExpand?: boolean;
  toggleExpand?: (expand: boolean) => void;
};

const SliderItem = ({ item, closeAll, disablePadding, isExpand, toggleExpand }: Props) => {
  const isExpandProps = () => {
    if (isExpand !== undefined && toggleExpand !== undefined) {
      return (
        <MarginedButton onClick={() => toggleExpand(!isExpand)} color="primary">
          {isExpand ? <ExpandLess /> : <ExpandMore />}
        </MarginedButton>
      );
    }
  };

  return (
    <SpaceBetweenCenterDiv>
      <NavbarLink to={item.path} style={{ width: "100%" }} onClick={closeAll}>
        <ListItemButton sx={{ paddingX: disablePadding ? "16px" : "32px" }}>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.name} />
        </ListItemButton>
      </NavbarLink>
      {isExpandProps()}
    </SpaceBetweenCenterDiv>
  );
};

export default SliderItem;

export const footer = {
  newsletter: {
    title: "Soyez informé",
    description: "Abonnez-vous à l'infolettre pour être informé des dernières nouvelles",
    label: "Courriel",
    button: "Souscrire"
  },
  navigation: {
    studio: {
      name: "Studio"
    }
  },
  claims: {
    copyrights: "Tous droits réservés"
  }
};

import { createTheme, responsiveFontSizes } from "@mui/material";


declare module "@mui/material/styles" {
  interface Palette {
    ternary: Palette["primary"];
    nav: Palette["primary"];
    body: Palette["primary"];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    ternary?: PaletteOptions["primary"];
    nav?: PaletteOptions["primary"];
    body?: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    ternary: true;
  }
}

declare module "@mui/material/TextField" {
  interface TextFieldPropsColorOverrides {
    ternary: true;
  }
}

const Light = createTheme({
  palette: {
    primary: {
      main: "#563e84",
      light: "#8978a9",
      dark: "#3c2b5c"
    },
    secondary: {
      main: "#fbebcb",
      light: "#fdf3e0",
      dark: "#b0a58e"
    },
    ternary: {
      main: "#a48ca4",
      light: "#bfafbf",
      dark: "#736273"
    },
    nav: {
      main: "#fbebcb",
      light: "#fdf3e0",
      dark: "#b0a58e"
    },
    text: {
      primary: "#563e84",
      secondary: "#ffffff"
    },
    background: {
      default: "#fbebcb"
    }
  },
  typography: {
    fontFamily: ["Montserrat", "sans-serif"].join(",")
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ":-webkit-any-link": {
          WebkitTapHighlightColor: "transparent"
        }
      }
    }
    // MuiTextField: {
    //     styleOverrides: {
    //         root: ({ theme }) => ({
    //             color: "red",
    //             backgroundColor: "white",
    //             borderRadius: theme.shape.borderRadius
    //         })
    //     },
    //
    // }
  }
});

export default responsiveFontSizes(Light);

import React from "react";
import { NavigationMetadata } from "../metadata";
import { HoverMenu } from "./HoverMenu";
import { FlexDiv } from "../../../shared/styled";
import { Button } from "./Button";
import { Views } from "../../../../global/model";


type Props = {
  data: NavigationMetadata;
};

export const Desktop = ({ data }: Props) => {
  return (
    <FlexDiv>
      <Button path={data[Views.ABOUT].path}>{data[Views.ABOUT].name}</Button>
      <HoverMenu data={data[Views.SERVICES]} />
      <Button path={data[Views.REALIZATIONS].path}>{data[Views.REALIZATIONS].name}</Button>
      <Button path={data[Views.CONTACT].path}>{data[Views.CONTACT].name}</Button>
    </FlexDiv>
  );
};

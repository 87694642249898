import React from "react";
import { FaFacebook, FaInstagram, FaSoundcloud, FaSpotify } from "react-icons/fa";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../../../global/i18n";


const ClaimsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "center"
  }
}));

const Copyrights = styled("div")(({ theme }) => ({
  display: "flex",
  width: "80%",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    justifyContent: "center"
  }
}));

const SocialNetworks = styled("div")(({ theme }) => ({
  fontSize: "20px",
  display: "flex",
  justifyContent: "space-between",
  width: "20%",
  [theme.breakpoints.down("sm")]: {
    marginTop: 10,
    width: "60%"
  }
}));

const currentYear = new Date().getFullYear();

export const Claims = () => {
  const { t } = useTranslation([NAMESPACE.FOOTER]);

  return (
    <ClaimsContainer>
      <Copyrights>
        {t(`${NAMESPACE.FOOTER}:claims.copyrights`)} © {currentYear} Studio Balance.
      </Copyrights>
      <SocialNetworks>
        <a href="https://www.facebook.com/laurent.savard.5" target="_blank" rel="noopener noreferrer">
          <FaFacebook color="white" />
        </a>
        <a href="https://www.instagram.com/ton.ami.lorenzo/" target="_blank" rel="noopener noreferrer">
          <FaInstagram color="white" />
        </a>
        <a href="https://open.spotify.com/artist/70I7Yps5q3NMKQ4U9gCuo8" target="_blank" rel="noopener noreferrer">
          <FaSpotify color="white" />
        </a>
        <a href="https://soundcloud.com/lorenzorose" target="_blank" rel="noopener noreferrer">
          <FaSoundcloud color="white" />
        </a>
      </SocialNetworks>
    </ClaimsContainer>
  );
};

import React from "react";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../global/i18n";
import { Views } from "../../global/model";
import { styled, Typography, useTheme } from "@mui/material";
import { Section } from "../../components/layout/Section";

import AboutPicture1 from "../../assets/content/about1.jpg";
import AboutPicture3 from "../../assets/content/about3.jpg";


const Header = styled(Section)(({ theme }) => ({
  paddingTop: theme.spacing(20),

  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  }
}));

const History = styled(Section)(({ theme }) => ({
  paddingTop: theme.spacing(8),
}));

const Location = styled(Section)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(8),

  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  }
}));

const LocationContent = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginRight: "16px",

  [theme.breakpoints.down("md")]: {
    width: "100%",
    marginRight: 0,
    marginBottom: theme.spacing(2),
  }
}));

const LocationImage = styled("img")(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  width: "40%",
  [theme.breakpoints.down("md")]: {
    width: "100%"
  }
}));

export const About = () => {
  const { t } = useTranslation([NAMESPACE.SHARED, Views.ABOUT]);
  const theme = useTheme();

  return (
    <>
      <Header bgImage={AboutPicture1} header>
        <Typography variant={"h2"} color={"text.secondary"} fontWeight="bold">
          {t(`${Views.ABOUT}:header.title`)}
        </Typography>
        <Typography variant={"body1"} color={"text.secondary"} width={{xs: "100%", md: "50%"}}>
          {t(`${Views.ABOUT}:header.description`)}
        </Typography>
      </Header>
      <History>
        <Typography variant={"h4"} fontWeight={"bold"}>
          {t(`${Views.ABOUT}:history.title`)}
        </Typography>
        <Typography variant={"body1"} paddingTop={2}>
          {t(`${Views.ABOUT}:history.description`)}
        </Typography>
      </History>
      <Location>
        <LocationContent>
          <Typography variant={"h4"} fontWeight={"bold"}>
            {t(`${Views.ABOUT}:location.title`)}
          </Typography>
          <Typography variant={"body1"} paddingTop={2}>
            {t(`${Views.ABOUT}:location.description`)}
          </Typography>
        </LocationContent>
        <LocationImage src={AboutPicture3}/>
      </Location>
    </>
  );
};

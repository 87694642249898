import React from "react";
import ReactDOM from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material";
import { RouterProvider } from "react-router-dom";
import { Router } from "./router";
// import PurpleTheme from "./global/theme/LufsTheme";
import { ToastProvider } from "./global/contexts/Toast";
import StudioBalanceTheme from "./global/theme/StudioBalanceTheme";


console.log("NODE_ENV:", process.env.NODE_ENV);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={StudioBalanceTheme}>
      <CssBaseline />
      <ToastProvider>
        <RouterProvider router={Router} fallbackElement={<div>SEEEEEESH</div>} />
      </ToastProvider>
    </ThemeProvider>
  </React.StrictMode>
);

import * as React from "react";
import { useEffect, useState } from "react";
import { AnimatePresence, motion, wrap } from "framer-motion";
import { styled } from "@mui/material/styles";
import { Avatar, MobileStepper, Typography } from "@mui/material";


const Container = styled("div")(({theme  }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  height: "40vh",
  overflow: "hidden",

  [theme.breakpoints.down("sm")]: {
    minHeight: "400px"
  }
}));

const Mask = styled("div")<{ color: string }>(({ theme, color }) => ({
  position: "relative",
  zIndex: 2,
  width: "100%",
  height: "100%",
  pointerEvents: "none",
  background: `linear-gradient(90deg, ${color} 0%, rgba(255,255,255,0) 10%, rgba(255,255,255,0) 50%, rgba(255,255,255,0) 90%, ${color} 100%)`,

  [theme.breakpoints.down("sm")]: {
    background: "none",
  }
}));

const Item = styled(motion.div)(({ theme }) => ({
  position: "absolute",
  overflow: "hidden",
  top: 0,
  width: "70%",
  height: "100%",
  cursor: "grab",

  [theme.breakpoints.down("md")]: {
    width: "80%"
  },

  [theme.breakpoints.down("sm")]: {
    width: "100%"
  }
}));

const Signature = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center"
}));


const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0
    };
  }
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};

type Props = {
  color: string;
  items: {
    name: string;
    title: string;
    picture: string;
    comment: string;
  }[];
  autoPlay?: boolean;
  timeout?: number;
};

export const DraggableCarousel = ({items, color, autoPlay = false, timeout = 5000} : Props) => {
  const [[page, direction], setPage] = useState([0, 0]);

  const itemIndex = wrap(0, items.length, page);

  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection]);
  };

  useEffect(() => {
    if (false) {
      const interval = setInterval(() => {
        paginate(1);
      }, timeout);
      return () => clearInterval(interval);
    }
  }, [page]);

  return (
    <Container>
      <Mask color={color} />
      <AnimatePresence initial={false} custom={direction}>
        <Item
          key={page}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: "spring", stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 }
          }}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x);

            if (swipe < -swipeConfidenceThreshold) {
              paginate(1);
            } else if (swipe > swipeConfidenceThreshold) {
              paginate(-1);
            }
          }}
        >
          <Typography variant={"h5"} marginBottom={2}>
            {items[itemIndex].comment}
          </Typography>
          <Signature>
            <Avatar
              src={items[itemIndex].picture}
              sx={{ width: 72, height: 72, bgcolor: 'secondary.light', marginRight: 2 }}
            />
            <div>
              <Typography variant={"subtitle2"} fontWeight={"bold"}>
                {items[itemIndex].name}
              </Typography>
              <Typography variant={"body2"}>
                {items[itemIndex].title}
              </Typography>
            </div>
          </Signature>
        </Item>
      </AnimatePresence>
      <MobileStepper
        variant="dots"
        // sx={{height: "70%"}}
        steps={items.length}
        position="static"
        activeStep={itemIndex}
        backButton={<> </>}
        nextButton={<></>}
      />
    </Container>
  );
};

export const realizations = {
  header: {
    title: "Réalisations",
    subtitle: "Voici le portfolio de projets musicaux et vidéos du sutdio Balance"
  },
  music: {
    title: "Musique",
    subtitle: "Écoutez quelques projets",
    sounds: {
      sound1: {
        title: "Dans les étoiles",
        description: ""
      },
      sound2: {
        title: "Les plus beaux souvenirs",
        description: ""
      },
      sound3: {
        title: "100 Pas",
        description: ""
      },
      sound4: {
        title: "Condamné - Medha",
        description: ""
      },
      sound5: {
        title: "Corde raides",
        description: ""
      },
      sound6: {
        title: "Loved and Broken",
        description: ""
      },
      sound7: {
        title: "Stay",
        description: ""
      },
      sound8: {
        title: "Vivre comme ça",
        description: ""
      },
      sound9: {
        title: "Won't waste another day - Charly Blue",
        description: ""
      }
    }
  },
  portfolio: {
    title: "Portfolio",
    subtitle: "Regardez quelques projets",
    projects: {
      project1: {
        title: "Projet: Tourisme Charlevoix - Été 2023",
        description: "Réalisation: Félix Gosselin, Production: Agence Oasis, Crédits sur le mixage sonore."
      },
      project2: {
        title: "Projet: ASSQ - MaNeige.ski",
        description: "Réalisation: Moonset, Production: Agence Oasis, Crédits sur le mixage sonore."
      },
      project3: {
        title: "Projet: Beenox - Femmes en jeu vidéo",
        description: "Réalisation: Moonset, Production: Moonset, Crédits sur le mixage sonore."
      },
      project4: {
        title: "Projet: Les Pères Nature - Recrutement",
        description: "Réalisation: Moonset, Production: Agence Minimal, Crédits sur l’enregistrement de la narration et le mixage sonore."
      },
      project5: {
        title: "Projet: Toiture Québec - 418Toiture",
        description: "Réalisation: Koopé, Production: Affichez inc. Crédits sur l’enregistrement de la narration, conception sonore et le mixage sonore."
      }
    }
  }
}
import React, { useEffect, useRef, useState } from "react";
import { Viewport } from "../../shared/Viewport";
import { styled } from "@mui/material/styles";
import { Theme, useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { ObserverProps } from "./Types";
import { height, minHeight, minWidth, SizingProps, SxProps, width } from "@mui/system";


const Container = styled("section")<SizingProps["width"] & SizingProps["minWidth"]
  & SizingProps["height"] & SizingProps["minHeight"]>(height, minHeight, width, minWidth, () => ({
  position: "relative",
}));

const BackgroundImage = styled("img")(() => ({
  position: "absolute",
  zIndex: -1,
  height: "100%",
  width: "100%",
  overflow: "hidden",
  objectFit: "cover",
  backgroundPosition: "center center"
}));

const BackgroundVideo = styled("video")(() => ({
  position: "absolute",
  zIndex: -1,
  height: "100%",
  width: "100%",
  overflow: "hidden",
  objectFit: "cover",
  backgroundPosition: "center center"
}));

const BackgroundBox = styled(Box)(() => ({
  position: "absolute",
  zIndex: -1,
  height: "100%",
  width: "100%",
  overflow: "hidden"
}));

type Props = {
  header?: boolean;
  enableObserver?: boolean;
  bgColor?: string;
  bgImage?: string;
  bgVideo?: string;
  sx?: SxProps<Theme>;
  className?: string;
  children: React.ReactElement | React.ReactElement[];
} & SizingProps["width"] & SizingProps["minWidth"] & SizingProps["height"] & SizingProps["minHeight"];

export const Section = ({
  height = "auto",
  width = "100%",
  minHeight = 0,
  minWidth = 0,
  header = false,
  enableObserver = false,
  bgColor,
  bgImage,
  bgVideo,
  sx,
  className,
  children
}: Props) => {
  const [isVisible, setIsVisible] = useState(!enableObserver);
  const theme = useTheme();
  const responsive = useMediaQuery(theme.breakpoints.down("md"));

  minHeight = header ? `calc(100vh - 56px)` : minHeight;

  const ref = useRef<HTMLDivElement | null>(null);

  if (bgColor === undefined) {
    bgColor = theme.palette.secondary.main;
  }

  const validatedChildren = React.Children.map(children, (child: React.ReactElement) => {
    if (React.isValidElement<ObserverProps>(child) && enableObserver) {
      return React.cloneElement(child, { isVisible: isVisible });
    }
    return child;
  });

  const callback = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries;
    // setIsVisible(entry.isIntersecting);
    if (entry.isIntersecting) {
      setIsVisible(true);
    }
  };

  const options = {
    root: null,
    rootMargin: "0px",
    threshold: responsive ? 0.65 : 0.4
  };

  useEffect(() => {
    if (!enableObserver) {
      return;
    }

    const observer = new IntersectionObserver(callback, options);
    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, options, isVisible]);

  const getBackground = () => {
    if (bgImage !== undefined) {
      return <BackgroundImage src={bgImage} alt="background" />;
    } else if (bgVideo !== undefined) {
      return (
        <BackgroundVideo autoPlay loop muted playsInline>
          <source src={bgVideo} type="video/mp4" />
        </BackgroundVideo>
      );
    } else {
      return <BackgroundBox bgcolor={bgColor} />;
    }
  };

  return (
    <Container height={height} width={width} minHeight={minHeight} minWidth={minWidth} header={header} ref={ref}>
      {getBackground()}
      <Viewport sx={{ ...sx, height: height, minHeight: minHeight, width: width, minWidth: minWidth }} className={className}>
        {validatedChildren}
      </Viewport>
    </Container>
  );
};

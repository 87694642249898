import React from "react";
import { styled } from "@mui/material/styles";
import { Viewport } from "../../shared/Viewport";
import { Divider } from "@mui/material";
import { Navigation } from "./Items/Navigation";
import { Claims } from "./Items/Claims";
import { Newsletter } from "./Items/Newsletter";


const FooterSummary = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column"
  }
}));

const FooterContainer = styled("footer")(({ theme }) => ({
  backgroundColor: theme.palette.nav.main,
  display: "flex",
  alignItems: "flex-end",
  paddingBottom: "20px",
  paddingTop: "20px",
  width: "100%",
  height: "100%"
}));

export const Footer = () => {

  return (
    <FooterContainer>
      <Viewport>
        <FooterSummary>
          <Newsletter />
          <Navigation />
        </FooterSummary>
        <Divider sx={{ backgroundColor: "secondary.light", marginY: "20px" }} />
        <Claims />
      </Viewport>
    </FooterContainer>
  );
};
